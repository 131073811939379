import { Segment } from "semantic-ui-react";

import App from "./components/App";

export default function AppsView({ channel = "latest" }) {
	return (
		<div style={{ margin: "0 auto", maxWidth: 500 }}>
			<Segment padded="very">
				<App channel={channel} app="astrid-studio" name="Astrid Studio" />
			</Segment>
		</div>
	);
}
