import { Menu } from "semantic-ui-react";

import { Link } from "react-router-dom";

const channels = [
	{
		key: "latest",
		name: "Stable",
	},
	{
		key: "beta",
		name: "Beta",
	},
	{
		key: "alpha",
		name: "Alpha",
	},
];

export default function ChannelsMenu({ selectedChannel }) {
	return (
		<Menu fluid widths={3} secondary size="large" style={{ justifyContent: "center" }}>
			{channels.map((channel) => (
				<Menu.Item
					as={Link}
					to={channel.key}
					key={channel.key}
					name={channel.name}
					active={selectedChannel === channel.key}
				/>
			))}
		</Menu>
	);
}
