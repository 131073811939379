import { Route } from "react-router-dom";

import organizationTypes from "astrid-firestore/src/api/organizations/constants/organizationTypes";

import App from "../../App";
import StudioRedirect from "../../components/StudioRedirect";
import DropboxApprove from "../../components/organization/DropboxApprove";
import ListProductionsLegacy from "../../components/production/ListProductions";
import renderComponent from "../../hocs/renderComponent";
import withMatch from "../../hocs/withMatch";
import withRight from "../../hocs/withRight";
import withUserProfile from "../../hocs/withUserProfile";
import Billing from "../../layouts/Billing";
import CalendarLegacy from "../../layouts/Calendar";
import ComputeJobs from "../../layouts/ComputeJobs";
import Distribution from "../../layouts/Distribution";
import ExternalUpload from "../../layouts/ExternalUpload";
import ForwardContext from "../../layouts/ForwardContext";
import Policy from "../../layouts/Policy";
import Publication from "../../layouts/Publication";
import PublisherLayout from "../../layouts/Publisher";
import Reader from "../../layouts/Reader";
import ReaderPreview from "../../layouts/ReaderPreview";
import ReaderSignup from "../../layouts/ReaderSignup";
import Scheduled from "../../layouts/Scheduled";
import ProducerSettings from "../../layouts/Settings";
import SharedExport from "../../layouts/SharedExport";
import StatisticsLayout from "../../layouts/StatisticsLayout";
import Superadmin from "../../layouts/Superadmin";
import Upload from "../../layouts/Upload";
import Words from "../../layouts/Words";
import HomeOld from "../../pages/Home/HomeOld";
import PageNotFound from "../ui/pages/PageNowFound/PageNotFound";

import Account from "../account/pages/Account";
import AccountSettings from "../account/pages/AccountSettings";
import ListAccountAgreements from "../account/pages/ListAccountAgreements";
import ListAccountPurchaseOrders from "../account/pages/ListAccountPurchaseOrders";
import ListAccountRevenues from "../account/pages/ListAccountRevenues";
import AdminAgreement from "../admin/pages/AdminAgreement";
import AdminAgreementTemplate from "../admin/pages/AdminAgreementTemplate";
import AdminAgreementTemplates from "../admin/pages/AdminAgreementTemplates";
import AdminArticle from "../admin/pages/AdminArticle/AdminArticle";
import AdminArticleArticleBundles from "../admin/pages/AdminArticle/AdminArticleArticleBundles";
import AdminArticleArtifacts from "../admin/pages/AdminArticle/AdminArticleArtifacts";
import AdminArticleDistribution from "../admin/pages/AdminArticle/AdminArticleDistribution";
import AdminArticleSettings from "../admin/pages/AdminArticle/AdminArticleSettings";
import AdminArticleVersion from "../admin/pages/AdminArticle/AdminArticleVersion";
import AdminArticleVersionChange from "../admin/pages/AdminArticle/AdminArticleVersionChange";
import AdminArticleVersions from "../admin/pages/AdminArticle/AdminArticleVersions";
import AdminArticleBundle from "../admin/pages/AdminArticleBundle/AdminArticleBundle";
import AdminArticleBundleArticles from "../admin/pages/AdminArticleBundle/AdminArticleBundleArticles";
import AdminArticleBundleDistribution from "../admin/pages/AdminArticleBundle/AdminArticleBundleDistribution";
import AdminArticleBundles from "../admin/pages/AdminArticleBundles";
import AdminArticles from "../admin/pages/AdminArticles";
import AdminArtifact from "../admin/pages/AdminArtifact/AdminArtifact";
import AdminArtifactArticles from "../admin/pages/AdminArtifact/AdminArtifactArticles";
import AdminArtifactFiles from "../admin/pages/AdminArtifact/AdminArtifactFiles";
import AdminArtifacts from "../admin/pages/AdminArtifacts";
import AdminAuthor from "../admin/pages/AdminAuthor/AdminAuthor";
import AdminAuthorArticles from "../admin/pages/AdminAuthor/AdminAuthorArticles";
import AdminAuthorSettings from "../admin/pages/AdminAuthor/AdminAuthorSettings";
import AdminAuthorTitles from "../admin/pages/AdminAuthor/AdminAuthorTitles";
import AdminAuthors from "../admin/pages/AdminAuthors";
import AdminCost from "../admin/pages/AdminCost";
import AdminDistribution from "../admin/pages/AdminDistribution/AdminDistribution";
import AdminDistributionPipeline from "../admin/pages/AdminDistribution/AdminDistributionPipeline";
import AdminDistributionPipelines from "../admin/pages/AdminDistribution/AdminDistributionPipelines";
import AdminDistributions from "../admin/pages/AdminDistributions";
import AdminExpenses from "../admin/pages/AdminExpenses";
import AdminExpiringAgreements from "../admin/pages/AdminExpiringAgreements";
import AdminImprint from "../admin/pages/AdminImprint/AdminImprint";
import AdminImprintArticles from "../admin/pages/AdminImprint/AdminImprintArticles";
import AdminImprintTitles from "../admin/pages/AdminImprint/AdminImprintTitles";
import AdminImprints from "../admin/pages/AdminImprints";
import AdminInvoices from "../admin/pages/AdminInvoices";
import AdminOffer from "../admin/pages/AdminOffer";
import AdminOrder from "../admin/pages/AdminOrder";
import AdminPendingAgreements from "../admin/pages/AdminPendingAgreements";
import AdminPrice from "../admin/pages/AdminPrice";
import AdminPrices from "../admin/pages/AdminPrices";
import AdminProducerUser from "../admin/pages/AdminProducerUser";
import AdminProductionDeliveries from "../admin/pages/AdminProductionDeliveries";
import AdminProductionsUnfinalized from "../admin/pages/AdminProductionsUnfinalized";
import AdminPublisher from "../admin/pages/AdminPublisher";
import AdminPublisherUser from "../admin/pages/AdminPublisherUser";
import AdminPublishers from "../admin/pages/AdminPublishers";
import AdminPurchaseOrders from "../admin/pages/AdminPurchaseOrders";
import AdminReader from "../admin/pages/AdminReader";
import AdminReaders from "../admin/pages/AdminReaders";
import AdminRevenues from "../admin/pages/AdminRevenues";
import AdminSalesOrders from "../admin/pages/AdminSalesOrders";
import AdminSerie from "../admin/pages/AdminSerie/AdminSerie";
import AdminSerieArticles from "../admin/pages/AdminSerie/AdminSerieArticles";
import AdminSerieTitles from "../admin/pages/AdminSerie/AdminSerieTitles";
import AdminSeries from "../admin/pages/AdminSeries";
import AdminStudio from "../admin/pages/AdminStudio";
import AdminStudios from "../admin/pages/AdminStudios";
import AdminTitle from "../admin/pages/AdminTitle/AdminTitle";
import AdminTitleArticles from "../admin/pages/AdminTitle/AdminTitleArticles";
import AdminTitleSettings from "../admin/pages/AdminTitle/AdminTitleSettings";
import AdminTitleVersions from "../admin/pages/AdminTitle/AdminTitleVersions";
import AdminTitles from "../admin/pages/AdminTitles";
import AdminTranslator from "../admin/pages/AdminTranslator/AdminTranslator";
import AdminTranslatorArticles from "../admin/pages/AdminTranslator/AdminTranslatorArticles";
import AdminTranslatorSettings from "../admin/pages/AdminTranslator/AdminTranslatorSettings";
import AdminTranslatorTitles from "../admin/pages/AdminTranslator/AdminTranslatorTitles";
import AdminTranslators from "../admin/pages/AdminTranslators";
import AdminUsers from "../admin/pages/AdminUsers";
import CreateAgreementTemplate from "../admin/pages/CreateAgreementTemplate";
import CreatePrices from "../admin/pages/CreatePrices";
import OrganizationSettingsFinance from "../admin/pages/OrganizationSettingsFinance";
import OrganizationSettingsLanguage from "../admin/pages/OrganizationSettingsLanguage";
import OrganizationSettingsStudio from "../admin/pages/OrganizationSettingsStudio";
import OrganizationSettingsVendors from "../admin/pages/OrganizationSettingsVendors";
import PrepareAgreements from "../admin/pages/PrepareAgreements";
import ProducerAdmin from "../admin/pages/ProducerAdmin";
import PublisherAdmin from "../admin/pages/PublisherAdmin";
import SiteLayout from "../app/layouts/SiteLayout/SiteLayout";
import Apps from "../apps/pages/Apps";
import Article from "../articles/pages/Article";
import Articles from "../articles/pages/Articles";
import Login from "../authentication/pages/Login";
import LoginForm from "../authentication/pages/LoginForm";
import ResetPassword from "../authentication/pages/ResetPassword";
import Calendar from "../calendar/pages/Calendar";
import Documents from "../documents/pages/Documents";
import Home from "../home/pages/Home";
import ListProductions from "../listproductions/pages/ListProductions";
import CompletedProductions from "../myproductions/pages/CompletedProductions";
import MyProductionOffers from "../myproductions/pages/MyProductionOffers";
import MyProductions from "../myproductions/pages/MyProductions";
import OngoingProductions from "../myproductions/pages/OngoingProductions";
import ProductionRequests from "../myproductions/pages/ProductionRequests";
import PublisherMyProductions from "../myproductions/pages/PublisherMyProductions";
import SentOffers from "../myproductions/pages/SentOffers";
import NarratorOrganizationContext from "../organizations/layouts/OrganizationContext/NarratorOrganizationContext";
import OrganizationContext from "../organizations/layouts/OrganizationContext/OrganizationContext";
import CreateProductionProducer from "../productions/pages/CreateProductionProducer";
import CreateProductionPublisher from "../productions/pages/CreateProductionPublisher";
import Production from "../productions/pages/Production";
import ProductionAudiobook from "../productions/pages/ProductionAudiobook";
import ProductionFinance from "../productions/pages/ProductionFinance";
import ProductionOverview from "../productions/pages/ProductionOverview";
import ProductionReaders from "../productions/pages/ProductionReaders";
import ProductionTeam from "../productions/pages/ProductionTeam";
import ProfilePage from "../profile/pages/ProfilePage";
import StorytoolArchive from "../storytoolArchive/pages/StorytoolArchive";
import SuperAdmin from "../superadmin/pages/SuperAdmin";
import SuperAdminAppendixes from "../superadmin/pages/SuperAdminAppendixes";
import SuperAdminApproveAgreementTemplates from "../superadmin/pages/SuperAdminApproveAgreementTemplates";
import SuperAdminApproveAgreements from "../superadmin/pages/SuperAdminApproveAgreements";
import SuperAdminApproveOffers from "../superadmin/pages/SuperAdminApproveOffers";
import SuperAdminApprovePrices from "../superadmin/pages/SuperAdminApprovePrices";
import SuperAdminApproveProductionPrices from "../superadmin/pages/SuperAdminApproveProductionPrices";
import SuperAdminArticles from "../superadmin/pages/SuperAdminArticles";
import SuperAdminBiblioCompletedTasks from "../superadmin/pages/SuperAdminBiblioCompletedTasks";
import SuperAdminBiblioSettings from "../superadmin/pages/SuperAdminBiblioSettings";
import SuperAdminBiblioTasks from "../superadmin/pages/SuperAdminBiblioTasks";
import SuperAdminBiblioUncompletedTasks from "../superadmin/pages/SuperAdminBiblioUncompletedTasks";
import SuperAdminDistributionChannel from "../superadmin/pages/SuperAdminDistributionChannel/SuperAdminDistributionChannel";
import SuperAdminDistributionChannelPipelines from "../superadmin/pages/SuperAdminDistributionChannel/SuperAdminDistributionChannelPipelines";
import SuperAdminDistributionChannelSettings from "../superadmin/pages/SuperAdminDistributionChannel/SuperAdminDistributionChannelSettings";
import SuperAdminDistributionChannelWorkflows from "../superadmin/pages/SuperAdminDistributionChannel/SuperAdminDistributionChannelWorkflows";
import SuperAdminDistributionChannels from "../superadmin/pages/SuperAdminDistributionChannels";
import SuperAdminDistributionPipeline from "../superadmin/pages/SuperAdminDistributionPipeline/SuperAdminDistributionPipeline";
import SuperAdminDistributionPipelineSettings from "../superadmin/pages/SuperAdminDistributionPipeline/SuperAdminDistributionPipelineSettings";
import SuperAdminDistributions from "../superadmin/pages/SuperAdminDistributions";
import SuperAdminProducer from "../superadmin/pages/SuperAdminProducer";
import SuperAdminProducers from "../superadmin/pages/SuperAdminProducers";
import SuperAdminProductionPrice from "../superadmin/pages/SuperAdminProductionPrice";
import SuperAdminPublisher from "../superadmin/pages/SuperAdminPublisher";
import SuperAdminPublishers from "../superadmin/pages/SuperAdminPublishers";
import SuperAdminUser from "../superadmin/pages/SuperAdminUser";
import SuperAdminUsers from "../superadmin/pages/SuperAdminUsers";
import Vendors from "../vendors/pages/Vendors";

const routes = (
	<Route path="/" element={<App />}>
		<Route path="readerSignup" element={renderComponent(withMatch(ReaderSignup))}>
			<Route path=":referrer/:campaign" element={null} />
			<Route path=":referrer" element={null} />
		</Route>
		<Route path="readerPreview/:ids" element={renderComponent(withMatch(ReaderPreview))} />
		<Route path="words" element={renderComponent(withMatch(Words))}>
			<Route path=":productionId/:email" element={null} />
			<Route path=":productionId" element={null} />
		</Route>
		<Route path="external/upload/:publisherId/:language" element={renderComponent(withMatch(ExternalUpload))} />
		<Route path="export" element={renderComponent(withMatch(SharedExport))}>
			<Route path=":productionId/:article/:id" element={null} />
			<Route path=":productionId/:article" element={null} />
			<Route path=":productionId" element={null} />
		</Route>
		<Route path="policy" element={<Policy />} />
		<Route path="login" element={<Login />}>
			<Route index element={<LoginForm />} />
			<Route path="reset" element={<ResetPassword />} />
			<Route path=":provider" element={<LoginForm />} />
		</Route>
		<Route element={<SiteLayout />}>
			<Route index element={<Home />} />
			<Route path="studio-redirect" element={<StudioRedirect />} />
			<Route path="home-old" element={renderComponent(withMatch(withUserProfile(HomeOld)))} />
			<Route path="apps" element={<Apps />}>
				<Route path=":channel" element={null} />
			</Route>
			<Route path="superadmin" element={renderComponent(withMatch(withUserProfile(Superadmin)))} />
			<Route path="computejobs" element={renderComponent(withMatch(withUserProfile(ComputeJobs)))} />
			<Route path="upload" element={renderComponent(withMatch(withUserProfile(Upload)))} />
			<Route path="settings/dropbox" element={renderComponent(withMatch(withUserProfile(DropboxApprove)))} />
			<Route path="settings" element={renderComponent(withMatch(withUserProfile(ProducerSettings)))}>
				<Route path=":producerId" element={null} />
			</Route>
			<Route path="billing" element={renderComponent(withMatch(withUserProfile(Billing)))} />
			<Route
				path="statistics"
				element={renderComponent(withMatch(withRight(StatisticsLayout, "viewProductionStatistics")))}
			/>
			<Route
				path="distribution"
				element={renderComponent(withMatch(withRight(Distribution, "distribution.editDistributors")))}
			/>
			<Route
				path="scheduled"
				element={renderComponent(withMatch(withRight(Scheduled, "distribution.editDistributors")))}
			/>
			<Route path="reader" element={<Reader />} />
			<Route path="readerSignup" element={<ReaderSignup signedIn />} />
			<Route path="readerPreview/:ids" element={renderComponent(withMatch(withUserProfile(ReaderPreview)))} />
			<Route path="words" element={renderComponent(withMatch(withUserProfile(Words)))}>
				<Route path=":productionId" element={null} />
			</Route>
			<Route path="export" element={renderComponent(withMatch(SharedExport))}>
				<Route path=":productionId/:article/:id" element={null} />
				<Route path=":productionId/:article" element={null} />
				<Route path=":productionId" element={null} />
			</Route>
			<Route path="profile/:userId" element={<ProfilePage />} />
			<Route
				path="calendar"
				element={renderComponent(withMatch(withRight(withUserProfile(CalendarLegacy), "viewCalendar")))}
			>
				<Route path=":productionId" element={null} />
			</Route>
			<Route path="publication" element={renderComponent(withMatch(withUserProfile(Publication)))} />
			<Route path="production" element={renderComponent(withMatch(withUserProfile(ListProductionsLegacy)))} />
			<Route path="production/:productionId" element={<Production />}>
				<Route index element={<ProductionOverview />} />
				<Route path="info" element={<ProductionOverview />}>
					<Route path=":modal" element={null} />
				</Route>
				<Route path="discussion" element={<ProductionOverview discussion />} />
				<Route path="discussionInternal" element={<ProductionOverview discussion="internal" />} />
				<Route path="articles" element={<ProductionAudiobook tab="articles" />} />
				<Route path="audiobook" element={<ProductionAudiobook tab="audiobook" />} />
				<Route path="readers" element={<ProductionReaders />} />
				<Route path="team" element={<ProductionTeam />} />
				<Route path="finance" element={<ProductionFinance />}>
					<Route path=":partyId" element={null} />
				</Route>
			</Route>
			<Route path="policy" element={<Policy />} />
			<Route path="admin" element={<SuperAdmin />}>
				<Route path="approve" element={<ForwardContext />}>
					<Route path="costs" element={<SuperAdminApproveProductionPrices />} />
					<Route path="prices" element={<SuperAdminApprovePrices />} />
					<Route path="offers" element={<SuperAdminApproveOffers />} />
					<Route path="agreements" element={<SuperAdminApproveAgreements />} />
					<Route path="templates" element={<SuperAdminApproveAgreementTemplates />} />
				</Route>
				<Route path="producers" element={<ForwardContext />}>
					<Route path="list" element={<SuperAdminProducers />} />
					<Route path=":id" element={<SuperAdminProducer />} />
				</Route>
				<Route path="publishers" element={<ForwardContext />}>
					<Route path="list" element={<SuperAdminPublishers />} />
					<Route path=":id" element={<SuperAdminPublisher />} />
				</Route>
				<Route path="users" element={<ForwardContext />}>
					<Route path="list" element={<SuperAdminUsers />} />
					<Route path=":id" element={<SuperAdminUser />} />
				</Route>
				<Route path="articles" element={<ForwardContext />}>
					<Route path="list" element={<SuperAdminArticles />} />
				</Route>
				<Route
					path="distributions"
					element={<ForwardContext />}
					handle={{ crumb: ({ t }) => t("distributions", "Distributions") }}
				>
					<Route index element={<SuperAdminDistributions />} />
				</Route>
				<Route
					path="distributions/channels"
					element={<ForwardContext />}
					handle={{ crumb: ({ t }) => t("channels", "Channels") }}
				>
					<Route index element={<SuperAdminDistributionChannels />} />
					<Route
						path=":channel"
						element={<SuperAdminDistributionChannel />}
						handle={{ crumb: ({ channel }) => channel?.name }}
					>
						<Route index element={<SuperAdminDistributionChannelSettings />} />
						<Route
							path="workflows"
							element={<SuperAdminDistributionChannelWorkflows />}
							handle={{ crumb: ({ t }) => t("workflows", "Workflows") }}
						/>
						<Route path="pipelines" handle={{ crumb: ({ t }) => t("pipelines", "Pipelines") }}>
							<Route index element={<SuperAdminDistributionChannelPipelines />} />
							<Route
								path=":id"
								element={<SuperAdminDistributionPipeline />}
								handle={{ crumb: ({ pipeline }) => pipeline?.name }}
							>
								<Route index element={<SuperAdminDistributionPipelineSettings />} />
							</Route>
						</Route>
					</Route>
				</Route>
				<Route path="users" element={<ForwardContext />}>
					<Route path="list" element={<SuperAdminUsers />} />
					<Route path="narrator" element={<SuperAdminUsers type="narrator" />} />
					<Route path="producer" element={<SuperAdminUsers type="producer" />} />
					<Route path="publisher" element={<SuperAdminUsers type="publisher" />} />
					<Route path="unassigned" element={<SuperAdminUsers type="unassigned" />} />
					<Route path=":id" element={<SuperAdminUser />} />
				</Route>
				<Route path="costs" element={<ForwardContext />}>
					<Route path=":productionId/:id" element={<SuperAdminProductionPrice />} />
				</Route>
				<Route path="agreements" element={<ForwardContext />}>
					<Route path="appendixes" element={<SuperAdminAppendixes />} />
				</Route>
				<Route path="biblio" element={<ForwardContext />}>
					<Route path="settings" element={<SuperAdminBiblioSettings />} />
					<Route path="tasks" element={<SuperAdminBiblioTasks />}>
						<Route index element={<SuperAdminBiblioUncompletedTasks />} />
						<Route path="completed" element={<SuperAdminBiblioCompletedTasks />} />
						<Route path="uncompleted" element={<SuperAdminBiblioUncompletedTasks />} />
					</Route>
				</Route>
			</Route>
			<Route path="account/:id" element={<Account />}>
				<Route path="vendor-settings" element={<Vendors />} />
				<Route path="agreements" element={<ListAccountAgreements />} />
				<Route path="revenues" element={<ListAccountRevenues />} />
				<Route path="orders" element={<ListAccountPurchaseOrders />} />
				<Route path="settings" element={<AccountSettings />} />
			</Route>
			<Route path="producer/:orgId" element={<OrganizationContext />}>
				<Route path="create-production" element={<CreateProductionProducer />} />
				<Route path="admin" element={<ProducerAdmin />}>
					<Route path="settings" element={<ForwardContext />}>
						<Route path="studio" element={<OrganizationSettingsStudio />} />
						<Route path="vendors" element={<OrganizationSettingsVendors />} />
						<Route path="finance" element={<OrganizationSettingsFinance />} />
						<Route path="language" element={<OrganizationSettingsLanguage />} />
					</Route>
					<Route path="agreements" element={<ForwardContext />}>
						<Route path="expiring" element={<AdminExpiringAgreements />} />
						<Route path="pending" element={<AdminPendingAgreements />} />
						<Route path="prepare" element={<PrepareAgreements />} />
						<Route path="prepare/:templateId" element={<PrepareAgreements />} />
						<Route path="templates" element={<AdminAgreementTemplates />} />
						<Route path="templates/:id" element={<AdminAgreementTemplate />} />
						<Route path="templates/create" element={<CreateAgreementTemplate />} />
						<Route path=":id" element={<AdminAgreement />} />
					</Route>
					<Route path="prices" element={<ForwardContext />}>
						<Route index element={<AdminPrices />} />
						<Route path="create" element={<CreatePrices />} />
						<Route path=":id" element={<AdminPrice />} />
					</Route>
					<Route path="users" element={<ForwardContext />}>
						<Route path="list" element={<AdminUsers />} />
						<Route path=":id" element={<AdminProducerUser />} />
					</Route>
					<Route path="readers" element={<ForwardContext />}>
						<Route path="list" element={<AdminReaders />} />
						<Route path=":id" element={<AdminReader />} />
					</Route>
					<Route path="studios" element={<ForwardContext />}>
						<Route path="gen1" element={<AdminStudios gen={1} />} />
						<Route path="gen2" element={<AdminStudios gen={2} />} />
						<Route path=":id" element={<AdminStudio />} />
					</Route>
					<Route path="publishers" element={<ForwardContext />}>
						<Route path="list" element={<AdminPublishers />} />
						<Route path=":id" element={<AdminPublisher />} />
					</Route>
					<Route path="costs" element={<ForwardContext />}>
						<Route path="expenses" element={<AdminExpenses />} />
						<Route path="revenues" element={<AdminRevenues />} />
						<Route path=":id" element={<AdminCost />} />
					</Route>
					<Route path="orders" element={<ForwardContext />}>
						<Route path="purchases" element={<AdminPurchaseOrders />} />
						<Route path="sales" element={<AdminSalesOrders />} />
						<Route path=":id" element={<AdminOrder />} />
					</Route>
					<Route path="invoices" element={<ForwardContext />}>
						<Route index element={<AdminInvoices />} />
					</Route>
					<Route path="offers" element={<ForwardContext />}>
						<Route path=":id" element={<AdminOffer />} />
					</Route>
					<Route path="productions" element={<ForwardContext />}>
						<Route path="costs" element={<AdminProductionsUnfinalized />} />
						<Route path="deliveries" element={<AdminProductionDeliveries />} />
					</Route>
				</Route>
				<Route path="list-productions" element={<ListProductions type={organizationTypes.PRODUCER} />} />
				<Route path="my-productions" element={<MyProductions type={organizationTypes.PRODUCER} />}>
					<Route path="current" element={<OngoingProductions />} />
					<Route path="completed" element={<CompletedProductions />} />
					<Route path="offers" element={<MyProductionOffers />} />
					<Route path="sent" element={<SentOffers />} />
					<Route path="offers/:id" element={<MyProductionOffers />} />
					<Route path="requests" element={<ProductionRequests />} />
				</Route>
				<Route path="documents" element={<Documents />} />
				<Route path="calendar" element={<Calendar />}>
					<Route path=":productionId" element={null} />
				</Route>
			</Route>
			<Route path="publisher/:orgId" element={<OrganizationContext />}>
				<Route index element={renderComponent(withMatch(withUserProfile(PublisherLayout)))} />
				<Route path="create-production" element={<CreateProductionPublisher />} />
				<Route path="articles" element={<ForwardContext />}>
					<Route index element={<Articles />} />
					<Route path=":id" element={<Article />} />
				</Route>
				<Route path="admin" element={<PublisherAdmin />}>
					<Route path="settings" element={<ForwardContext />}>
						<Route path="vendors" element={<OrganizationSettingsVendors />} />
						<Route path="language" element={<OrganizationSettingsLanguage />} />
						<Route path="finance" element={<OrganizationSettingsFinance />} />
					</Route>
					<Route path="agreements" element={<ForwardContext />}>
						<Route path=":id" element={<AdminAgreement />} />
					</Route>
					<Route path="users" element={<ForwardContext />}>
						<Route path="list" element={<AdminUsers />} />
						<Route path=":id" element={<AdminPublisherUser />} />
					</Route>
					<Route
						path="titles"
						element={<ForwardContext />}
						handle={{ crumb: ({ t }) => t("titles", "Titles") }}
					>
						<Route index element={<AdminTitles />} />
						<Route path=":title" element={<AdminTitle />} handle={{ crumb: ({ title }) => title?.name }}>
							<Route index element={<AdminTitleSettings />} />
							<Route path="articles" element={<AdminTitleArticles />} />
							<Route path="productions" />
							<Route path="versions" element={<AdminTitleVersions />} />
						</Route>
					</Route>
					<Route
						path="articles"
						element={<ForwardContext />}
						handle={{ crumb: ({ t }) => t("articles", "Articles") }}
					>
						<Route index element={<AdminArticles />} />
						<Route
							path=":article"
							element={<AdminArticle />}
							handle={{ crumb: ({ article }) => article?.name }}
						>
							<Route index element={<AdminArticleSettings />} />
							<Route path="bundles" element={<AdminArticleArticleBundles />} />
							<Route path="artifacts" handle={{ crumb: ({ t }) => t("artifacts", "Artifacts") }}>
								<Route index element={<AdminArticleArtifacts />} />
								<Route
									path=":artifact"
									element={<AdminArtifact />}
									handle={{ crumb: ({ artifact }) => artifact?.name }}
								>
									<Route index element={<AdminArtifactFiles />} />
									<Route path="articles" element={<AdminArtifactArticles />} />
								</Route>
							</Route>
							<Route path="distribution" element={<AdminArticleDistribution />} />
							<Route path="productions" />
							<Route path="versions" handle={{ crumb: ({ t }) => t("versions", "Versions") }}>
								<Route index element={<AdminArticleVersions />} />
								<Route
									path=":version"
									element={<AdminArticleVersion />}
									handle={{ crumb: ({ version }) => version?.id }}
								>
									<Route path="after" element={<AdminArticleVersionChange change="after" />} />
									<Route path="before" element={<AdminArticleVersionChange change="before" />} />
									<Route
										path="revert"
										element={<AdminArticleVersionChange change="after" revert />}
									/>
								</Route>
							</Route>
						</Route>
					</Route>
					<Route
						path="articles/bundles"
						element={<ForwardContext />}
						handle={{ crumb: ({ t }) => t("bundles", "Bundles") }}
					>
						<Route index element={<AdminArticleBundles />} />
						<Route
							path=":bundle"
							element={<AdminArticleBundle />}
							handle={{ crumb: ({ bundle }) => bundle?.name }}
						>
							<Route index element={<AdminArticleBundleArticles />} />
							<Route path="distribution" element={<AdminArticleBundleDistribution />} />
						</Route>
					</Route>
					<Route path="artifacts" element={<ForwardContext />}>
						<Route path="list" element={<AdminArtifacts />} />
					</Route>
					<Route
						path="series"
						element={<ForwardContext />}
						handle={{ crumb: ({ t }) => t("serie", "Series") }}
					>
						<Route index element={<AdminSeries />} />
						<Route path=":serie" element={<AdminSerie />} handle={{ crumb: ({ serie }) => serie?.name }}>
							<Route index element={<AdminSerieTitles />} />
							<Route path="articles" element={<AdminSerieArticles />} />
						</Route>
					</Route>

					<Route
						path="imprints"
						element={<ForwardContext />}
						handle={{ crumb: ({ t }) => t("imprints", "Imprints") }}
					>
						<Route index element={<AdminImprints />} />
						<Route
							path=":imprint"
							element={<AdminImprint />}
							handle={{ crumb: ({ imprint }) => imprint?.name }}
						>
							<Route index element={<AdminImprintTitles />} />
							<Route path="articles" element={<AdminImprintArticles />} />
						</Route>
					</Route>

					<Route
						path="authors"
						element={<ForwardContext />}
						handle={{ crumb: ({ t }) => t("authors", "Authors") }}
					>
						<Route index element={<AdminAuthors />} />
						<Route
							path=":author"
							element={<AdminAuthor />}
							handle={{ crumb: ({ author }) => author?.name }}
						>
							<Route index element={<AdminAuthorSettings />} />
							<Route path="titles" element={<AdminAuthorTitles />} />
							<Route path="articles" element={<AdminAuthorArticles />} />
						</Route>
					</Route>

					<Route
						path="translators"
						element={<ForwardContext />}
						handle={{ crumb: ({ t }) => t("translators", "Translators") }}
					>
						<Route index element={<AdminTranslators />} />
						<Route
							path=":translator"
							element={<AdminTranslator />}
							handle={{ crumb: ({ translator }) => translator?.name }}
						>
							<Route index element={<AdminTranslatorSettings />} />
							<Route path="titles" element={<AdminTranslatorTitles />} />
							<Route path="articles" element={<AdminTranslatorArticles />} />
						</Route>
					</Route>

					<Route
						path="distributions"
						element={<ForwardContext />}
						handle={{ crumb: ({ t }) => t("distributions", "Distributions") }}
					>
						<Route index element={<AdminDistributions />} />
						<Route
							path=":distribution"
							element={<AdminDistribution />}
							handle={{ crumb: ({ distribution }) => distribution?.id }}
						>
							<Route index element={<AdminDistributionPipeline />} />
							<Route
								path="pipelines"
								handle={{ crumb: ({ t }) => t("pipelines", "Pipelines") }}
								element={<AdminDistributionPipelines />}
							/>
						</Route>
					</Route>
					<Route path="costs" element={<ForwardContext />}>
						<Route path="expenses" element={<AdminExpenses />} />
						<Route path=":id" element={<AdminCost />} />
					</Route>
					<Route path="orders" element={<ForwardContext />}>
						<Route path="purchases" element={<AdminPurchaseOrders />} />
						<Route path=":id" element={<AdminOrder />} />
					</Route>
				</Route>
				<Route path="list-productions" element={<ListProductions type={organizationTypes.PUBLISHER} />} />
				<Route path="my-productions" element={<PublisherMyProductions />}>
					<Route path="current" element={<OngoingProductions />} />
					<Route path="completed" element={<CompletedProductions />} />
				</Route>
			</Route>
			<Route path="narrator/:orgId" element={<NarratorOrganizationContext />}>
				<Route path="calendar" element={<Calendar />} />
				<Route path="my-productions" element={<MyProductions type={organizationTypes.NARRATOR} />}>
					<Route path="current" element={<OngoingProductions />} />
					<Route path="completed" element={<CompletedProductions />} />
					<Route path="offers" element={<MyProductionOffers />} />
					<Route path="offers/:id" element={<MyProductionOffers />} />
				</Route>
			</Route>
			<Route path="storytool-archive" element={<StorytoolArchive />} />
		</Route>
		<Route path="*" element={<PageNotFound />} />
	</Route>
);

export default routes;
