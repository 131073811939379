export const defaultColumnsPublisher = {
	series: true,
	author: true,
	producer: false,
	progressBar: true,
	productionType: true,
	productionTags: false,
	publisherManagerNames: true,
	isbn: false,
	ebookISBN: false,
	language: false,
	readerNames: false,
	voiceNames: false,
	managerNames: false,
	subcontractorName: false,
	duration: false,
	scriptDate: false,
	doneDate: false,
	created: false,
	updated: false,
};

export const defaultColumnsProducer = {
	series: true,
	author: true,
	publisher: true,
	publisherManagerNames: false,
	progressBar: true,
	noOfCorrections: true,
	productionType: true,
	productionTags: false,
	isbn: false,
	ebookISBN: false,
	language: false,
	readerNames: false,
	voiceNames: false,
	editorNames: false,
	prooferNames: false,
	managerNames: false,
	subcontractorName: false,
	duration: false,
	scriptDate: false,
	doneDate: false,
	created: false,
	updated: false,
};
