import { parsePhoneNumber } from "awesome-phonenumber";
import moment from "moment";

import { Divider, Grid, Header, Icon, Segment } from "semantic-ui-react";

import React from "react";
import { useTranslation } from "react-i18next";

import Flex from "astrid-components/lib/components/Layout/Flex";

import { formatDate } from "../../../helpers/date";
import DropdownMenu from "../../ui/components/DropdownMenu/DropdownMenu";
import ImageAvatar from "../../ui/components/ImageAvatar/ImageAvatar";

import { useProfile } from "../../authentication/state/profile";
import useHasRight from "../../authorization/hooks/useHasRight";
import useHasRole from "../../authorization/hooks/useHasRole";
import { useOrganizationId } from "../../organizations/state/organizationId";
import VoiceReaderLanguages from "../../readers/components/ReaderSearchResults/components/ReaderLanguages";

import ReaderInformation from "../components/ReaderInformation";

export default function ProfileView({ user }) {
	const { i18n, t } = useTranslation();
	const profile = useProfile();
	const hasRight = useHasRight();
	const organizationId = useOrganizationId();
	const hasRole = useHasRole();

	const organization = user.organizations?.[organizationId];

	const dropdownOptions = [
		{
			text: t("editAccount", "Edit account"),
			linkTo: `/account/${user.id}/settings`,
			hidden: user.id !== profile.id,
		},
		{
			text: t("editInOrganization", "Edit in {{organization}}", { organization: organization?.name }),
			linkTo: `/${organization?.type}/${organizationId}/admin/users/${user.id}`,
			hidden: !hasRight("users.view") || !organization,
		},
		{
			text: t("editInSuperAdmin", "Edit in super admin"),
			linkTo: `/admin/users/${user.id}`,
			hidden: !hasRole("admin", "owner"),
		},
	];

	return (
		<Segment padded>
			<Grid style={{ maxWidth: 1400, margin: "0 auto" }}>
				<Grid.Row>
					<Grid.Column width={4}>
						<ImageAvatar src={user.img} size="medium" avatar={false} style={{ borderRadius: 10 }} />
					</Grid.Column>

					<Grid.Column width={11}>
						<Flex style={{ marginBottom: "1em" }}>
							<Header as="h2" style={{ margin: 0 }}>
								{user.firstName} {user.lastName}
							</Header>

							<DropdownMenu iconStyle={{ fontSize: 15 }} options={dropdownOptions} />
						</Flex>

						<Flex style={{ flexDirection: "column", marginTop: 5 }} gap={5} alignItems="flex-start">
							{user.visibleContactDetails && (
								<>
									<div>
										{<Icon name="mail" />}
										{user.email}
									</div>

									{user.phone && (
										<div>
											{<Icon name="phone" />}
											{parsePhoneNumber(user.phone).getNumber("international")}
										</div>
									)}
								</>
							)}

							{user.dateOfBirth && (
								<div>
									{<Icon name="birthday" />}
									{formatDate(user.dateOfBirth)} (
									{moment().diff(formatDate(user.dateOfBirth), "years")} {t("yearsOld", "y/o")})
								</div>
							)}

							{user.gender && (
								<div>
									{<Icon name="venus mars" />}
									{t(user.gender)}
								</div>
							)}
						</Flex>

						{user.languages && (
							<>
								<Divider />
								<VoiceReaderLanguages languageSkills={user?.languages} />
							</>
						)}

						{user.readerData && <ReaderInformation user={user} />}
					</Grid.Column>
				</Grid.Row>
			</Grid>
		</Segment>
	);
}
