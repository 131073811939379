import { useHits } from "react-instantsearch-core";

import ControlledTable from "../../../../ui/components/ControlledTable/ControlledTable";

export default function Hits({ columns, selectedColumns }) {
	const { hits } = useHits();

	return (
		<ControlledTable
			selectedColumns={selectedColumns}
			columns={columns}
			data={hits?.map((result) => ({
				id: result.objectID,
				title: result.title,
				isbn: result.isbn,
				ebookISBN: result.ebookISBN,
				readerNames: result.readerNames?.join(", "),
				voiceNames: result.voiceNames?.join(", "),
				editorNames: result.editorNames?.join(", "),
				prooferNames: result.prooferNames?.join(", "),
				managerNames: result.managerNames?.join(", "),
				subcontractorName: result.subcontractorName,
				author: result.author?.join(","),
				status: result.status,
				productionDate: result.productionDate,
				productionType: result.productionType,
				productionTags: result.productionTags?.join(", "),
				deliveryDate: result.deliveryDate,
				duration: result.duration,
				firstDoneDate: result.firstDoneDate,
				created: result.created,
				updated: result.updated,
				progressBar: result.progressBar,
				noOfCorrections: result.progressBar?.corrected,
				producer: result.producerName,
				publisher: result.publisherName,
				publisherManagerNames: result.publisherManagerNames?.join(", "),
				series: `${result.series || ""} ${result.part || ""}`,
				language: result.language,
				scriptDate: result.scriptDate,
			}))}
		/>
	);
}
