import { Icon, Label } from "semantic-ui-react";

import React from "react";

import Popup from "../../../../ui/components/Popup/Popup";

export default function SessionInformationLabel({ popupContent, icon, value }) {
	return (
		<Label style={{ margin: 0 }}>
			<Popup position="left center" content={popupContent} trigger={<Icon style={{ width: 20 }} name={icon} />} />
			{value ? value : "N/A"}
		</Label>
	);
}
