import { Howl } from "howler";
import debounce from "lodash/debounce";
import get from "lodash/get";
import memoize from "lodash/memoize";

import { Button, Icon, Popup } from "semantic-ui-react";

import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import analyzeSilences from "astrid-compute/src/shared/analyzeSilences";
import { base, db, firebase } from "astrid-firebase";
import Navigation from "astrid-web/src/components/Timeline/Navigation";
import { secToDuration } from "astrid-web/src/helpers/fnc";

import MasterSettingsChild from "./MasterSettings";

class MasterSettings extends Component {
	state = {
		uploadParts: {},
		source: {},
		zoom: this.props.article === "cd" ? 8 : 5,
		offset: 0,
		silences: {},
		modal: null,
		splitPopup: null,

		// settings, stored to database
		overrides: {},
		sentenceSilence: 0.8,
		chapterSilence: this.props.article === "cd" ? 0.5 : 4,
		duration: 60 * 5,

		// player state
		trackLeft: 0,
		trackRight: 0,
		position: 0,
		currentPlayingPart: 0,
		skip: "sentence",

		masterPartArray: null,
	};

	playheadEl = React.createRef();
	trackEl = React.createRef();
	players = {};
	silences = {};
	uploadQueue = [];

	UNSAFE_componentWillMount() {
		base.bindDoc("/productions/" + this.props.productionId + "/meta/master", {
			context: this,
			state: "source",
		});
	}

	componentDidMount() {
		// set previous settings
		const { article, production } = this.props;
		let settings = get(production, "master." + article + ".settings");

		// override totalCD settings
		if (this.props.totalCD) {
			const discSettings = get(
				production,
				this.props.totalCD === "cd" ? "master.totalCD.settings" : "master.mp3cd.settings",
			);
			settings = {
				...settings,
				...(discSettings || {}),
			};

			if (!discSettings) this.props.setModifiedSettings(article);
		}

		if (settings && settings.duration) {
			this.setState({
				overrides: settings.overrides,
				sentenceSilence: settings.sentenceSilence,
				chapterSilence: settings.chapterSilence,
				duration: settings.duration,
				sentenceSilence_debounced: settings.sentenceSilence,
				chapterSilence_debounced: settings.chapterSilence,
				duration_debounced: settings.duration,
			});
		}
	}

	componentWillUnmount() {
		// turn off and destroy players
		this.pause();
		if (this.players)
			Object.values(this.players).forEach((player) => {
				player.unload();
			});
	}

	getSilences = memoize(
		(masterParts) => {
			if (masterParts)
				Promise.all(
					masterParts.map(([file, info]) => {
						if (info.json_master || info.json)
							return fetch(info.json_master ? info.json_master.url : info.json.url)
								.then((response) => response.json())
								.then((json) => [file, json])
								.catch((err) => {
									console.log("json err", err);
								});
					}),
				).then((parts) => {
					this.setState({
						silences: parts.reduce((acc, [file, json]) => {
							acc[file] = json;
							return acc;
						}, {}),
					});
				});
		},
		(...args) => JSON.stringify(args),
	);

	discDurations = memoize(
		(parts, silences) => {
			const discs = [];
			let partStart = 0;
			let partEnd = 0;
			let discStart = 0;

			parts.forEach(([key, info]) => {
				partStart = info.start;
				partEnd = info.start + info.duration;

				if (silences[key])
					silences[key]
						.filter((split) => split.split === "disc")
						.forEach((split) => {
							discs.push({
								start: discStart,
								duration: partStart + split.end - discStart,
								end: partStart + split.end,
							});
							discStart = partStart + split.end;
						});
			});
			discs.push({
				start: discStart,
				duration: partEnd - discStart,
				end: partEnd,
			});

			return discs;
		},
		(...args) => JSON.stringify(args),
	);

	handleScroll = debounce((e) => {
		const track = this.trackEl.current;

		if (track) {
			const trackLeft = track.scrollLeft;
			const trackRight = trackLeft + track.offsetWidth;
			this.setState({
				trackLeft,
				trackRight,
				offset: this.unzoom(trackLeft) * 1000,
			});
		}
	}, 20);

	handleRange = debounce((name) => {
		this.setState({ [name + "_debounced"]: +this.state[name] });
		this.props.setModifiedSettings(this.props.article);
	}, 100);

	zoom = (val) => this.state.zoom * val * (this.state.zoom / 3);
	unzoom = (val) => val / this.state.zoom / (this.state.zoom / 3);

	updateParts = memoize(
		(masterParts) => {
			// massage data
			let start = 0;
			const sortedMasters = masterParts
				.filter((file) => this.state.source[file])
				.map((file) => {
					const info = {
						...this.state.source[file],
						start,
					};
					start = start + (info.duration || 180); // random placeholder value to get some size in timeline navigation
					return [file, info];
				});

			return sortedMasters;
		},
		(...args) => JSON.stringify(args),
	);

	analyzeSilences = memoize(analyzeSilences, (...args) => JSON.stringify(args));

	override = (file, index, action, skipState) => {
		let overrides = this.state.overrides;

		// fix some legacy data (array formatted)
		if (!(typeof overrides === "object" && !Array.isArray(overrides))) {
			overrides = {};
		}

		// add override
		const to = action;

		if (!overrides[file]) overrides[file] = {};
		if (!overrides[file][to]) overrides[file][to] = [];
		overrides[file][to].push(index);

		// existing overrides to take out?
		const from = ["off", "sentence", "chapter", "intro", "disc"];
		from.splice(from.indexOf(to), 1);
		from.forEach((f) => {
			if (overrides[file] && overrides[file][f] && overrides[file][f].includes(index))
				overrides[file][f].splice(overrides[file][f].indexOf(index), 1);
		});

		if (!skipState) {
			this.setState({
				overrides,
			});
			this.props.setModifiedSettings(this.props.article);
		}
	};

	play = (playCallback) => {
		const { masterPartArray } = this.state;

		if (!this.sound || Object.keys(this.players).length !== masterPartArray.length) {
			this.setState({ loading: true });
			masterPartArray.forEach(([file, info], index) => {
				if (!this.players[file])
					this.players[file] = new Howl({
						src: [info.mp4_master ? info.mp4_master.url : info.mp4.url],
						html5: true, // to allow playing without downloading full file
						preload: true,
						// onloaderror: (id, err) => {},
						// onplayerror: (id, err) => {},
						onplay: () => {
							this.setState({
								playing: true,
								loading: false,
							});
						},
						onend: this.playNext,
					});
			});

			if (!this.sound) {
				const [firstPart] = masterPartArray[this.state.currentPlayingPart];
				this.sound = this.players[firstPart];
			}
		} else {
			this.sound = this.players[masterPartArray[this.state.currentPlayingPart][0]];
		}

		if (playCallback) {
			this.sound.once("play", playCallback);
		}

		this.seek(this.state.position);
		this.sound.play();

		this.interval = setInterval(this.updatePlayhead, 200);
	};

	playNext = () => {
		const { masterPartArray } = this.state;

		const nextPart = masterPartArray && masterPartArray[this.state.currentPlayingPart + 1];

		if (nextPart) {
			this.setState({ loading: true, currentPlayingPart: this.state.currentPlayingPart + 1 });
			this.sound = this.players[nextPart[0]];
			this.sound.seek(0);
			this.sound.play();
		} else {
			this.pause();
		}
	};

	pause = () => {
		if (this.interval) clearInterval(this.interval);
		if (this.introTimer1) clearInterval(this.introTimer1);
		if (this.introTimer2) clearInterval(this.introTimer2);
		if (this.sound) this.sound.pause();

		this.setState({
			playing: false,
		});
	};

	seek = (s, intro) => {
		const { masterPartArray } = this.state;

		const isPlaying = this.sound && this.sound.playing();
		let currentPlayingPart = this.state.currentPlayingPart;

		// is it another part?
		if (
			this.state.partSkip || // set by skip function
			s < masterPartArray[currentPlayingPart][1].start ||
			s > masterPartArray[currentPlayingPart][1].start + masterPartArray[currentPlayingPart][1].duration
		) {
			currentPlayingPart = masterPartArray.findIndex(
				([file, info]) => info.start <= s && info.start + info.duration > s,
			);
			this.setState({ currentPlayingPart, partSkip: false });

			if (this.sound) {
				this.sound.pause();
				const [file] = masterPartArray[currentPlayingPart];
				this.sound = this.players[file];
				if (isPlaying) {
					this.setState({ loading: true });
					this.sound.play();
				}
			}
		}

		// set state and player position
		this.setState({ position: s });
		if (this.sound) {
			this.sound.seek(s - masterPartArray[currentPlayingPart][1].start);

			// if same file as current, check that playhead ended up in correct spot (avoid weird behaviour of the audio player sometimes...)
			if (!this.state.loading && !this.state.partSkip && !intro) {
				setTimeout(() => {
					if (this.getPosition() < s - 1) {
						// debugger;
						console.log("CORRECTION", s, this.getPosition());
						this.seek(s);
					}
				}, 100);
			}
		}
	};

	getPosition = () => {
		const { masterPartArray } = this.state;

		const currentPlayingPart = this.state.currentPlayingPart;
		let howlPos = this.sound && this.sound.seek();

		// howler.js bug returns object sometimes, hacky way of solving...
		// https://github.com/goldfire/howler.js/issues/1189
		if (isNaN(howlPos)) {
			howlPos = this.sound && this.sound._sounds && this.sound._sounds[0] && this.sound._sounds[0]._seek;
		}

		if (!howlPos) howlPos = 0;

		return howlPos + masterPartArray[currentPlayingPart][1].start;
	};

	skip = (backwards) => {
		const { masterPartArray } = this.state;

		const currentPlayingPart = this.state.currentPlayingPart;

		// find active split point
		let checkPart = currentPlayingPart;
		let split;

		while (checkPart !== false && !split) {
			const part = masterPartArray[checkPart];

			if (part) {
				// get part info
				const partStart = part[1].start;
				const partEnd = partStart + part[1].duration;

				// get silences
				let silences = this.silences[masterPartArray[checkPart][0]];
				if (backwards) silences = [...silences].reverse();

				// get position
				let pos =
					checkPart === currentPlayingPart
						? this.getPosition()
						: // if skipping, use part duration info instead of playhead position
						!backwards
						? partStart
						: partEnd;

				// look for active split
				split = silences.find(
					(silence) =>
						((this.state.skip === "sentence" && silence.split) ||
							(this.state.skip === "chapter" && silence.split === "chapter") ||
							(this.state.skip === "disc" && silence.split === "disc")) &&
						(backwards ? silence.end < pos - 1 - partStart : silence.start >= pos - partStart),
				);

				if (split) {
					// go to split
					this.setState({ currentPlayingPart: checkPart });
					this.seek(split.end + partStart - 0.2);

					this.playheadEl.current.offsetParent.scrollTo({
						top: 0,
						left: this.zoom(split.start + partStart - 5),
					});
				} else {
					// check next part again
					checkPart = checkPart + (backwards ? -1 : 1);
				}
			} else {
				// reached the end, no hits
				checkPart = false;
			}
		}
	};

	playDiscIntro = (index, playOn) => {
		const allSilences = Object.values(this.state.silences)[0];
		const activeSilences = Object.values(this.silences)[0].filter((split) => split.split === "chapter");

		this.play(() => {
			const titleStart = allSilences[0].end;
			const titleEnd = activeSilences[0].end;
			const duration = index ? (titleEnd - titleStart) * 1000 - 100 : 0;
			if (index) {
				// play title first
				this.seek(titleStart, true);
			}

			// then disc intro
			this.introTimer1 = setTimeout(() => {
				const introStart = activeSilences[index].end;
				const introEnd = activeSilences[index + 1] ? activeSilences[index + 1].end : 500;
				const introDuration = (introEnd - introStart) * 1000 - 100;

				this.seek(introStart, true);
				this.introTimer2 = setTimeout(() => {
					this.pause();
					playOn();
				}, introDuration);
			}, duration);
		});
	};

	updatePlayhead = () => {
		const { masterPartArray } = this.state;

		const currentPlayingPart = this.state.currentPlayingPart;
		const pos = this.getPosition();

		if (!isNaN(pos)) {
			this.setState({ position: pos });

			// scroll into view
			const playheadPassedRight =
				this.playheadEl.current.offsetLeft -
				(this.playheadEl.current.offsetParent.scrollLeft + this.playheadEl.current.offsetParent.offsetWidth);

			if (playheadPassedRight > -20 && playheadPassedRight < 0) {
				this.playheadEl.current.offsetParent.scrollTo({
					top: 0,
					left: this.zoom(pos),
					behavior: "smooth",
				});
			}

			// play disc intro?
			if (this.props.totalCD) {
				let newDiscIndex = -1;
				const intro = this.silences[masterPartArray[currentPlayingPart][0]].find(
					(split) => split.split === "intro",
				);

				if (intro && intro.end > pos && intro.end < pos + 0.2) newDiscIndex = 0;

				if (newDiscIndex < 0) {
					const discDurations = this.discDurations(masterPartArray, this.silences);
					newDiscIndex = discDurations.findIndex((disc) => disc.start > pos && disc.start < pos + 0.2);
				}

				// if (newDiscIndex >= 0) {
				// 	this.pause();
				// 	this.seek(pos + 0.15);
				// 	this.playingIntro = true;
				// 	this.props.playDiscIntro(newDiscIndex, () => {
				// 		this.play();
				// 		this.playingIntro = false;
				// 	});
				// }
			}
		}
	};

	makeFileChapters = (direction) => {
		const { masterPartArray } = this.state;
		const silences = this.silences;

		Object.entries(silences).forEach(([file, partSilences], i) => {
			if (partSilences) {
				// remove existing
				partSilences
					.filter((s) => s.split === "chapter")
					.forEach((s) => {
						// let first autochapter be
						if (!(!i && s.index === 1)) {
							// reset rest
							this.override(file, s.index, "off");
						}
					});

				// set first silence of each part (except first part)
				if (direction === "first" && i && !partSilences[0].start)
					this.override(file, partSilences[0].index, "chapter");

				// set last silence of each part (except last part)
				if (direction === "last" && i + 1 !== Object.keys(silences).length) {
					const part = masterPartArray[i][1];
					const lastSilence = partSilences[partSilences.length - 1];

					if (lastSilence.end > part.duration - 0.01) this.override(file, lastSilence.index, "chapter");
				}
			}
		});
	};

	componentDidUpdate(prevProps, prevState, snapshot) {
		const { article, production } = this.props;
		const { source } = this.state;

		// get parts
		const masterParts = get(production, "master." + article + ".files") || [];
		const masterPartArray = masterParts.length ? this.updateParts(masterParts, source) : null;

		// convert parts into sorted array with some calculated data
		if (masterPartArray !== this.state.masterPartArray) {
			this.setState({ masterPartArray });

			this.getSilences(masterPartArray);
		}
	}

	render() {
		const {
			t,
			article,
			production,
			productionId,
			productionRights,
			modifiedSettings,
			setModifiedSettings,
			totalCD,
		} = this.props;

		const {
			source,
			splitPopup,
			loading,

			// zoom,
			offset,
			duration,
			sentenceSilence,
			chapterSilence,
			// debounced values used for calculations
			duration_debounced,
			sentenceSilence_debounced,
			chapterSilence_debounced,
			silences,

			trackLeft,
			trackRight,
			position,
			playing,
			skip,
			overrides,
			showSettings,

			masterPartArray,
		} = this.state;

		let partStart = 0;
		let splitChapters = 1;
		let splitParts = 1;

		// masterPartArray.forEach(([file, info], partIndex) => {
		//
		// })

		const uploadingOrProcessing = masterPartArray && masterPartArray.find(([file, info]) => info.status !== "done");

		// figure out disc durations
		const discDurations =
			totalCD &&
			masterPartArray &&
			silences &&
			Object.keys(silences).length &&
			Object.values(silences)[0].length &&
			this.discDurations(masterPartArray, this.silences);
		const currentDisc =
			discDurations &&
			discDurations.length &&
			discDurations.findIndex((disc) => disc.start <= position + 0.3 && disc.end >= position + 0.3);
		const maxDiscDuration = (get(production, "deliveryCD.duration") || 74) * 60;

		// trigger scroll handler to get track width
		if (masterPartArray && !trackRight && this.trackEl.current) this.handleScroll();

		return (
			<>
				{productionRights.includes("production.masterExportEdit") && (
					<>
						{masterPartArray && !!masterPartArray.length && (
							<div className={playing ? "playing" : ""} style={{ marginTop: "2em", width: "100%" }}>
								<div className="flex-stack" style={{ marginBottom: "1em", alignItems: "flex-end" }}>
									<div>
										<Button
											icon="step backward"
											size="tiny"
											disabled={loading}
											onClick={(e) => {
												this.skip(true);
											}}
										/>
										{playing ? (
											<Button
												icon="pause"
												color="teal"
												size="tiny"
												loading={loading}
												onClick={(e) => {
													this.pause();
												}}
											/>
										) : (
											<Button
												icon="play"
												color="teal"
												size="tiny"
												loading={loading}
												onClick={(e) => {
													this.play();
												}}
											/>
										)}
										<Button
											icon="step forward"
											size="tiny"
											disabled={loading}
											onClick={(e) => {
												this.skip();
											}}
										/>
										{article !== "cd" && (
											<div className="master-settings-toggle">
												<Button.Group size="tiny" className="divided">
													<Button
														color={skip === "sentence" ? "green" : null}
														onClick={(e) => {
															this.setState({ skip: "sentence" });
														}}
													>
														{t("all")}
													</Button>
													<Button
														color={skip === "chapter" ? "blue" : null}
														onClick={(e) => {
															this.setState({ skip: "chapter" });
														}}
													>
														{t("chapter")}
													</Button>
													{totalCD === "cd" && (
														<Button
															color={skip === "disc" ? "orange" : null}
															onClick={(e) => {
																this.setState({ skip: "disc" });
															}}
														>
															{t("disc")}
														</Button>
													)}
												</Button.Group>
											</div>
										)}
										<b style={{ display: "inline-block", minWidth: 80 }}>
											{secToDuration(position, false)}
										</b>
										{totalCD === "cd" && currentDisc >= 0 && (
											<table
												style={{
													display: "inline-table",
													minWidth: 80,
													fontSize: 12,
													verticalAlign: "bottom",
												}}
											>
												<tbody>
													{Object.values(discDurations).map((disc, i) => (
														<tr key={i}>
															<td>
																{t("disc")} {i + 1}
															</td>
															<td>
																<Icon name="clock outline" />
																<b
																	style={
																		disc.duration > maxDiscDuration
																			? { color: "red" }
																			: null
																	}
																>
																	{secToDuration(disc.duration, false)}
																</b>{" "}
																{currentDisc === i && position - disc.start > 0 && (
																	<>
																		<Icon name="play" size="small" />
																		{secToDuration(position - disc.start, false)}
																	</>
																)}
															</td>
														</tr>
													))}
												</tbody>
											</table>
										)}
									</div>
									{showSettings && (
										<div style={{ width: "100%", maxWidth: 700 }}>
											{[
												{ name: "zoom", label: "Zoom", min: 1, max: 10 },
												{
													name: "chapterSilence",
													label: article === "cd" ? t("introPause") : t("chapterPause"),
													min: article === "cd" ? 0.4 : 0.75,
													max: 10,
													step: 0.01,
													display: (sec) => sec + " " + t("sec"),
													debounce: true,
												},
												{
													name: "sentenceSilence",
													label: t("partPause"),
													min: 0.75,
													max: 10,
													step: 0.01,
													display: (sec) => sec + " " + t("sec"),
													debounce: true,
													hidden: article === "cd",
												},
												{
													name: "duration",
													label: t("partDuration"),
													min: 60,
													max: 60 * 60,
													display: (sec) => Math.round(sec / 0.6) / 100 + " " + t("minutes"),
													debounce: true,
													hidden: article === "cd",
												},
											]
												.filter((range) => !range.hidden)
												.map((range) => (
													<div key={range.name} className="flex-stack">
														<b style={{ width: "20%" }}>{range.label}</b>

														<input
															type="range"
															value={this.state[range.name]}
															min={range.min}
															max={range.max}
															step={range.step}
															onChange={(e) => {
																this.setState({ [range.name]: +e.target.value });
																if (range.debounce) this.handleRange(range.name);
															}}
															style={{ width: "70%" }}
														/>
														<span style={{ width: "10%" }}>
															&nbsp;
															{range.display
																? range.display(this.state[range.name])
																: this.state[range.name]}
														</span>
													</div>
												))}
										</div>
									)}

									<div>
										<Button
											color="teal"
											size="tiny"
											content={t("firstChapterPart")}
											onClick={() => {
												this.makeFileChapters("first");
											}}
										/>
										<Button
											color="teal"
											size="tiny"
											content={t("lastChapterPart")}
											onClick={() => {
												this.makeFileChapters("last");
											}}
										/>

										<Button
											icon="settings"
											size="tiny"
											color={showSettings ? "grey" : "blue"}
											onClick={(e) => {
												this.setState({ showSettings: !showSettings });
											}}
											content={t("settings")}
										/>
									</div>
								</div>

								<Navigation
									theme={article === "cd" ? "intro" : "master"}
									clips={masterPartArray.map(([file, info]) => ({
										// massage some data
										id: file,
										position: info.start * 1000,
										start: info.start * 1000,
										end: (info.start + (info.duration || 180)) * 1000,
									}))}
									offset={offset}
									size={this.unzoom(trackRight - trackLeft) * 1000}
									length={Math.max(
										// calculate end of last part for total length
										(masterPartArray[masterPartArray.length - 1][1].start +
											masterPartArray[masterPartArray.length - 1][1].duration) *
											1000,
										// or use track size if that's bigger
										this.unzoom(trackRight - trackLeft) * 1000,
									)}
									onChangeSize={(val) => {
										let zoomLevel = (this.trackEl.current.offsetWidth / val) * 1000;
										if (zoomLevel < 1) zoomLevel = 1;
										else if (zoomLevel > 10) zoomLevel = 10;

										this.setState({
											zoom: zoomLevel,
										});
									}}
									onChangeOffset={(val) => {
										this.setState({ offset: val });
										this.playheadEl.current.offsetParent.scrollTo({
											top: 0,
											left: this.zoom(val / 1000),
										});
									}}
								/>

								<div
									ref={this.trackEl}
									className="master-track"
									onScroll={this.handleScroll}
									onClick={(e) => {
										if (e.target.classList.contains("master-waveform")) {
											const rect = e.currentTarget.getBoundingClientRect();
											const x = this.trackEl.current.scrollLeft + e.clientX - rect.left;
											this.seek(this.unzoom(x));
										}
									}}
								>
									{masterPartArray.map(([file, info], partIndex) => {
										// reset/start looking for sparse sentence splits from 0
										if (!partStart) {
											this.lastSparse = 0;
											this.lastDisc = 0;
											this.outroSilence = 0;
										}

										const nextPart = masterPartArray[partIndex + 1];
										const nextPartStartsWithSilence =
											!nextPart ||
											(silences[nextPart[0]] &&
												silences[nextPart[0]][0] &&
												!silences[nextPart[0]][0].start);

										const {
											analyzedSilences,
											lastSparse: newLastSparse,
											lastDisc: newLastDisc,
											outroSilence: newOutroSilence,
										} = silences[file]
											? this.analyzeSilences(
													this.lastSparse,
													this.lastDisc,
													totalCD === "cd" ? maxDiscDuration : false,
													this.outroSilence,
													silences[file],
													partStart,
													nextPartStartsWithSilence,
													file,
													info,
													overrides,
													sentenceSilence_debounced || sentenceSilence,
													chapterSilence_debounced || chapterSilence,
													duration_debounced || duration,
											  )
											: [];

										// store last sparse split to be used in next part analyses
										this.lastSparse = newLastSparse;
										this.lastDisc = newLastDisc;
										this.outroSilence = newOutroSilence;
										this.silences[file] = analyzedSilences;

										// sum up chapters
										splitChapters =
											splitChapters +
											(analyzedSilences
												? analyzedSilences.filter((s) => s.split === "chapter").length
												: 0);

										splitParts =
											splitParts +
											(analyzedSilences
												? analyzedSilences.filter(
														(s) => s.split === "chapter" || s.split === "sentence",
												  ).length
												: 0);

										const visibleSilences = analyzedSilences
											? analyzedSilences.filter(
													(silence) =>
														!(silence.start === 0 && !partIndex) &&
														this.zoom(partStart + silence.start) >= trackLeft &&
														this.zoom(partStart + silence.end) <= trackRight,
											  )
											: [];

										// keep track of where this part started on the total track
										const thisPartStart = partStart;
										partStart = partStart + (info.duration || 100);

										const waves = info.waves_master || info.waves || [info.wave];

										return (
											<div
												key={file}
												className="master-track-part"
												style={{
													width: info.duration ? this.zoom(info.duration) : 300,
												}}
											>
												{/* <Popup
													key={file}
													position="top center"
													content={
														<div>
															<Icon name="file outline" /> {file.substr(14)}
														</div>
													}
													hoverable
													size="tiny"
													trigger={ */}
												<div className="partinfo">
													<b>
														{t("file")} {partIndex + 1}
													</b>{" "}
													<Icon name="clock outline" />
													{info.duration
														? secToDuration(info.duration, false)
														: t("processedPart")}
												</div>
												{/* }
												/> */}
												<div className={"master-track-waves " + article}>
													{waves.map(
														(wave) =>
															wave && (
																<img
																	key={wave.path}
																	src={wave.url}
																	alt=""
																	style={{
																		width: (wave.percent || 100) + "%",
																	}}
																	// style={zoom < 6 ? { imageRendering: "pixelated" } : {}}
																	className="master-waveform"
																/>
															),
													)}
												</div>

												{visibleSilences.map((silence, i) => (
													<Popup
														key={i}
														position="top center"
														content={
															<div>
																<p style={{ textAlign: "center" }}>
																	<b>{t("startPart")}</b>{" "}
																	{secToDuration(
																		silence.start + thisPartStart,
																		false,
																	)}
																	<br />
																	<b>{t("duration") + ":"}</b>{" "}
																	{Math.round(silence.duration * 100) / 100} t("sec")
																</p>

																<Button.Group vertical labeled icon className="divided">
																	{[
																		{
																			label: t("none"),
																			condition: !silence.split,
																			color: "grey",
																			action: "off",
																		},
																		{
																			label: t("partPause"),
																			condition: silence.split === "sentence",
																			color: "green",
																			action: "sentence",
																			hidden: article === "cd" && !totalCD,
																		},
																		{
																			label:
																				article === "cd" && !totalCD
																					? t("discIntro")
																					: t("chapter"),
																			condition: silence.split === "chapter",
																			color: "blue",
																			action: "chapter",
																		},

																		{
																			label: t("firstDiscIntro"),
																			condition: silence.split === "intro",
																			color: "orange",
																			action: "intro",
																			hidden: totalCD !== "cd",
																		},
																		{
																			label: t("newDisc"),
																			condition: silence.split === "disc",
																			color: "orange",
																			action: "disc",
																			hidden: totalCD !== "cd",
																		},
																	]
																		.filter((btn) => !btn.hidden)
																		.map((btn) => (
																			<Button
																				key={btn.action}
																				content={btn.label}
																				icon={
																					btn.condition
																						? "check circle"
																						: "remove circle"
																				}
																				color={btn.condition ? btn.color : null}
																				onClick={(e) => {
																					if (!btn.condition) {
																						this.override(
																							file,
																							silence.index,
																							btn.action,
																						);
																					}

																					this.setState({ splitPopup: null });
																				}}
																			/>
																		))}
																</Button.Group>
															</div>
														}
														open={splitPopup === partIndex + "_" + i}
														size="tiny"
														trigger={
															<div
																className={"master-track-silence " + silence.split}
																style={{
																	left: this.zoom(silence.start),
																	width: this.zoom(silence.duration),
																}}
																onClick={(e) => {
																	const id = partIndex + "_" + i;
																	this.seek(thisPartStart + silence.end - 0.2);
																	this.setState({
																		splitPopup: splitPopup === id ? null : id,
																	});
																}}
															/>
														}
													/>
												))}
											</div>
										);
									})}
									<div
										ref={this.playheadEl}
										className="master-playhead"
										style={{ left: this.zoom(position) }}
									/>
								</div>
							</div>
						)}
					</>
				)}

				{(modifiedSettings === article || !!masterPartArray?.length) && (
					<p style={{ marginTop: "2em" }}>
						{masterPartArray &&
							masterPartArray.length > 1 &&
							masterPartArray.length + " " + t("files") + ", "}
						{splitChapters + " " + t("chapterParts")}
						{", " + splitParts + " " + t("splitParts")}

						{(modifiedSettings === article ||
							get(production, "master." + article + ".settings.approved") === false) && (
							<>
								<br />
								<br />

								<Button
									primary
									content={
										t("saveSettings") + (uploadingOrProcessing ? t("uploadedPartsProcessing") : "")
									}
									disabled={!!uploadingOrProcessing}
									onClick={(e) => {
										this.setState({ showSettings: false, splitPopup: null });
										setModifiedSettings(false);

										// MEMO: legacy flag, perhaps batch delete and just use splitChapters.
										const hasChapterSplits = !!splitChapters;

										const newData = {
											["master." +
											(totalCD ? (totalCD === "cd" ? "totalCD" : "mp3cd") : article) +
											".settings"]: {
												approved: true,
												overrides,
												sentenceSilence: sentenceSilence,
												chapterSilence: chapterSilence,
												hasChapterSplits,
												nofFfiles: masterPartArray.length,
												nofChapters: splitChapters,
												nofParts: splitParts,
												duration: duration,
												date: firebase.firestore.FieldValue.serverTimestamp(),
											},
										};

										// store duration
										const dur = secToDuration(
											Math.round(
												masterPartArray[masterPartArray.length - 1][1].start +
													masterPartArray[masterPartArray.length - 1][1].duration,
											),
										);
										if (article === "total") {
											newData.deliveryDuration = dur;
										} else if (article.substr(0, 5) === "part_") {
											// part article, run transaction separately
											const pi = article.split("_")[1];

											const prodRef = db.collection("productions").doc(productionId);
											db.runTransaction((transaction) => {
												return transaction.get(prodRef).then((doc) => {
													const partData = doc.data().deliveryParts;
													partData[pi].duration = dur;

													return transaction.update(prodRef, { deliveryParts: partData });
												});
											})
												.then(() => {
													console.log("Transaction done");
												})
												.catch(function (err) {
													console.error(err);
												});
										}

										db.collection("productions").doc(productionId).update(newData);
									}}
								/>
							</>
						)}
					</p>
				)}

				{((article === "cd" && modifiedSettings !== "cd") || article === "mp3cd") && (
					<MasterSettingsChild
						{...this.props}
						article="total"
						totalCD={article}
						playDiscIntro={this.playDiscIntro}
					/>
				)}
			</>
		);
	}
}

export default withTranslation()(MasterSettings);
