import cloneDeep from "lodash/cloneDeep";
import get from "lodash/get";
import moment from "moment";

import { Button, Form, Header } from "semantic-ui-react";

import React, { useState } from "react";
import { useTranslation, withTranslation } from "react-i18next";

import distConfig from "astrid-config/src/distributors";
import exportOptions from "astrid-config/src/exportOptions";
import { base, db, firebase } from "astrid-firebase";
import addEvent from "astrid-firebase/src/utils";
import { getDeliveryObstacles, getDistOptions, sendToDistributor } from "astrid-web/src/components/production/fnc";
import { durationToSec, secToDuration, toDate } from "astrid-web/src/helpers/fnc";

import FinishProductionDistOptionsModal from "./FinishProductionDistOptionsModal";

const getArticlePath = (article) =>
	article === "total"
		? "" // root
		: article.substr(0, 4) === "part"
		? "deliveryParts[" + article.split("_")[1] + "]." // part
		: "deliveryEbook."; // ebook

const isMetaOutdated = (production, article, distributor) => {
	const path = getArticlePath(article);

	const metaUpdated = toDate(get(production, `${path}release.${distributor}.metaUpdated`));
	const metaUpdatedArticle = toDate(get(production, path ? `${path}metaUpdated` : "metaUpdatedTotalArticle"));
	const metaUpdatedProduction = toDate(get(production, "metaUpdated"));

	// which was updated last?
	const metaLatest = Math.max(metaUpdated || 0, metaUpdatedArticle || 0, metaUpdatedProduction || 0);
	const metaSent = get(production, `${path}release.${distributor}.metaSent`);
	const metaOutdated = !metaSent || toDate(metaSent) < metaLatest;

	return metaOutdated;
};

const getActiveArticles = (production) => {
	const activeArticles = [];

	if (typeof production.isbn === "string") activeArticles.push("total");
	if (production.deliveryEbook) activeArticles.push("ebook");
	if (production.deliveryParts) activeArticles.push("part");
	if (production.deliveryCD) activeArticles.push("cd");
	if (production.deliveryMP3CD) activeArticles.push("mp3cd");

	return activeArticles;
};

const getDeliveryActions = ({ production, distributors, distributionOptions, publisher }) => {
	const timestamp = +new Date();
	const activeArticles = getActiveArticles(production);
	const sendMetadata = [];
	const sendExistingExport = [];
	const createExportAndSend = [];
	const laterMetadata = [];
	const laterExport = [];

	// replace part article with actual parts
	if (activeArticles.includes("part")) {
		activeArticles.splice(activeArticles.indexOf("part"), 1);

		production.deliveryParts.forEach((part, i) => {
			activeArticles.push("part_" + i);
		});
	}

	// loop all relevant articles
	activeArticles.forEach((article) => {
		const articleType = article.substr(0, 4) === "part" ? "part" : article;
		const distOptions = distributionOptions[articleType] || [];
		const expOptions = exportOptions(articleType);

		const path = getArticlePath(article);

		// loop selected distributors
		distOptions
			.filter(
				(distributor) =>
					get(distributionOptions, `settings.${articleType}.${distributor}.automatic`) &&
					// skip if distributor has been removed from global distribution setup
					distributors[distributor],
			)
			.forEach((distributor) => {
				const releaseDate = toDate(get(production, `${path}release.${distributor}.date`));

				const isGrouped = distributors[distributor].automation === "group";
				const isScheduled =
					distributors[distributor].automation === "schedule" &&
					!distributors[distributor].excludeMeta &&
					(!releaseDate ||
						moment(releaseDate).diff(moment(), "days") >= distributors[distributor].scheduleDays);

				// get obstacles
				const distOptions = getDistOptions({ production, publisher });
				const obstacles = distributors[distributor].excludeMeta
					? 0 // no metadata, no obstacles
					: getDeliveryObstacles({
							production,
							distOptions,
							singleArticle: article,
							singleDistributor: distributor,
					  });

				const sendLater = isGrouped || isScheduled || obstacles?.length;

				// send metadata if the distributor accepts and the metadata is old
				if (
					distConfig[distributor].meta &&
					// cd and mp3cd doesn't have metadata
					!["cd", "mp3cd"].includes(articleType) &&
					isMetaOutdated(production, article, distributor) &&
					!get(distributionOptions, `settings.${articleType}.${distributor}.excludeMeta`) &&
					!distributors[distributor].excludeMeta
				) {
					if (!sendLater) {
						// send right away
						sendMetadata.push({
							article,
							distributor,
						});
					} else {
						// send grouped
						laterMetadata.push({
							article,
							distributor,
						});
					}
				}

				// send exports
				if (!["ebook"].includes(articleType)) {
					const settingPath = `settings.${articleType}.${distributor}`;
					const format = get(distributionOptions, `${settingPath}.format`) || expOptions.format.default;
					const bitrate = get(distributionOptions, `${settingPath}.bitrate`) || expOptions.bitrate.default;
					const part = get(distributionOptions, `${settingPath}.part`) || expOptions.part.default;
					const iso = articleType === "mp3cd";
					const ddp = articleType === "cd";

					// check for existing export
					const exps = get(production, `master.${article}.exports`) || {};
					const exportHit = Object.entries(exps).find(
						([id, exp]) =>
							exp.format === format &&
							exp.part === part &&
							((exp.iso && iso) || (!exp.iso && !iso)) &&
							((exp.ddp && ddp) || (!exp.ddp && !ddp)) &&
							(format === "wav" || exp.bitrate === bitrate),
					);

					let newExport = null;
					if (exportHit) {
						// export exists
						const [exportId, exportInfo] = exportHit;
						const expired =
							!exportInfo.status ||
							toDate(exportInfo.created) < toDate(production.master[article].settings.date);

						if (!expired) {
							// exists and current
							const exportSent = get(exportInfo, `distributor.${distributor}.sent`);

							if (!exportSent || toDate(exportSent) < toDate(exportInfo.created)) {
								if (!sendLater) {
									// just send it (if not already sent)
									sendExistingExport.push({
										exportId,
										article,
										distributor,
									});
								} else {
									// will be sent later by group automation
									laterExport.push(distributor);
								}
							}
						} else {
							// expired, generate new
							newExport = {
								exportId,
								article,
								distributor,
								settings: {
									format,
									bitrate,
									part,
									iso,
									ddp,
								},
							};
						}
					} else {
						// does not exist, generate new
						newExport = {
							exportId: timestamp + format + article + part + (bitrate || ""), // for uniqueness and merging
							article,
							distributor,
							settings: {
								format,
								bitrate,
								part,
								iso,
								ddp,
							},
						};
					}

					// create new or expired export
					if (newExport) {
						createExportAndSend.push(newExport);

						// will be sent later action count
						if (sendLater) laterExport.push(distributor);
					}
				}
			});
	});

	return {
		sendMetadata,
		sendExistingExport,
		createExportAndSend,
		laterMetadata,
		laterExport,
		total: sendMetadata.length + sendExistingExport.length + createExportAndSend.length,
		scheduled: laterExport.length + laterMetadata.length,
	};
};

const deliverProduction = ({
	productionId,
	production,
	uid,
	profile,
	publisher,
	distributors,
	distOptions,
	updateStatus,
	setInitiatingDelivery,
	totalPartDuration,
	handleChange,
}) => {
	const actions =
		distOptions && getDeliveryActions({ production, distributors, distributionOptions: distOptions, publisher });
	console.log("Delivery actions", actions);
	// set deliveryDuration if parts total are longer
	if (
		totalPartDuration &&
		production.deliveryDuration &&
		totalPartDuration > durationToSec(production.deliveryDuration || "00:00:00")
	) {
		handleChange(null, { name: "deliveryDuration", value: secToDuration(totalPartDuration) });
	}

	updateStatus("done");
	if (!distOptions || !(actions.total || actions.scheduled)) {
		// nothing to do, just change status
		window.scrollTo(0, 0);
	} else {
		// send existing exports
		actions.sendExistingExport.forEach((exp) => {
			let audioOnly = true;

			// send metadata at the same time?
			const sendMetadataIndex = actions.sendMetadata.findIndex(
				(act) => exp.article === act.article && exp.distributor === act.distributor,
			);
			if (sendMetadataIndex > -1) {
				audioOnly = false;
				// unqueue the metadata action
				actions.sendMetadata.splice(sendMetadataIndex, 1);
				actions.total--;
			}

			// send it
			console.log("send existing", exp, audioOnly);

			const articles = { [productionId]: { [exp.article]: exp.exportId } };
			sendToDistributor(exp.distributor, articles, profile, uid, audioOnly, true);

			// set export status to queued
			// prettier-ignore
			const distData = {
				["master." + exp.article + ".exports." + exp.exportId + ".distributor." + exp.distributor + ".status"]: "queued",
				["master." + exp.article + ".exports." + exp.exportId + ".distributor." + exp.distributor + ".sent"]: firebase.firestore.FieldValue.serverTimestamp(),
			};

			db.collection("productions")
				.doc(productionId)
				.update(distData)
				.catch((err) => {
					console.log(err);
				});
		});

		// create new exports

		// merge exports with same settings
		const merged = cloneDeep(actions.createExportAndSend).reduce((prev, curr) => {
			if (!prev[curr.exportId]) {
				prev[curr.exportId] = { ...curr };
				prev[curr.exportId].distributor = {};
			}

			// set status for nonscheduled distributors
			if (!actions.laterExport.includes(curr.distributor)) {
				prev[curr.exportId].distributor[curr.distributor] = { status: "queued", sent: new Date() };
			}

			return prev;
		}, {});

		console.log("Merged exports", merged);

		Object.values(merged).forEach((exp) => {
			let audioOnly = true;

			// send metadata at the same time
			const sendMetadataIndex = actions.sendMetadata.findIndex((act) => act.article && act.distributor);
			if (sendMetadataIndex > -1) {
				audioOnly = false;

				// unqueue the metadata action
				actions.sendMetadata.splice(sendMetadataIndex, 1);
				actions.total--;
			}

			// create it (and queue send)
			console.log("create new", exp, audioOnly);

			// no bitrate for wav
			if (exp.settings.format === "wav") delete exp.settings.bitrate;

			const data = {
				master: {
					[exp.article]: {
						exports: {
							[exp.exportId]: {
								...exp.settings,
								created: firebase.firestore.FieldValue.serverTimestamp(),
								user: uid,
								status: "queued",
								distributor: exp.distributor,
							},
						},
					},
				},
			};

			db.collection("productions")
				.doc(productionId)
				.set({ ...data, updated: firebase.firestore.FieldValue.serverTimestamp() }, { merge: true })
				.then(() => {
					// start job
					base.addToCollection("tasks/compute/jobs", {
						job: "createExport",
						status: "queued",
						created: firebase.firestore.FieldValue.serverTimestamp(),
						diskType: "pd-ssd",
						diskSize: 150,
						machineType: "n1-highmem-4",
						args: {
							// keepAlive: true,
							article: exp.article,
							production: productionId,
							export: exp.exportId,
							audioOnly,
							automatedDelivery: true,
						},
					}).then(() => {
						// store event
						addEvent({ productionId, user: uid, email: profile.email, data });
					});
				})
				.catch((err) => {
					console.log("export create err:", err);
					//handle error
				});
		});

		// send metadata only
		actions.sendMetadata.forEach((md) => {
			console.log("send metadata only", md, production);
			const articles = { [productionId]: { [md.article]: "metadata" } };
			sendToDistributor(md.distributor, articles, profile, uid, false, true);
		});

		// set nof actions being performed on prod
		if (actions.scheduled) {
			db.collection("productions").doc(productionId).update({ scheduled: true });
		}

		setInitiatingDelivery(false);
	}
};

const ProductionDelivery = ({
	productionId,
	uid,
	profile,
	production,
	totalPartDuration,
	deliveryDuration,
	publisher,
	distributors,
	updateStatus,
	setParentState,
	handleChange,
}) => {
	const [initiatingDelivery, setInitiatingDelivery] = useState(false);
	const { t } = useTranslation();
	const activeArticles = getActiveArticles(production);
	const distOptions = getDistOptions({ production, publisher });
	const [showDistOptionsModal, setShowDistOptionsModal] = useState(false);

	// show stoppers
	const deliveryProblems = [];

	if (activeArticles.includes("total")) {
		if (!(deliveryDuration !== null || production.deliveryDuration)) deliveryProblems.push(t("specifyTotDuration"));

		if (
			!get(production, "master.total.settings.approved") &&
			production?.distributionOptions?.settings?.total?.storytoolWav?.part !== "parts"
		)
			deliveryProblems.push(t("uploadMasterAndSaveExp"));
	}

	if (activeArticles.includes("part")) {
		if (production.deliveryParts.find((part, i) => !part.duration))
			deliveryProblems.push(t("specifyDurationForParts"));

		if (production.deliveryParts.find((part, i) => !get(production, "master.part_" + i + ".settings.approved")))
			deliveryProblems.push(t("uploadMasterAndSaveExpForEachPart"));
	}

	if (activeArticles.includes("cd")) {
		if (!get(production, "master.cd.settings.approved")) deliveryProblems.push(t("uploadIntroAndSaveCdExports"));

		const requireIFCD = distOptions && distOptions.cd && distOptions.cd.find((dist) => distConfig[dist].ifcd);
		if (requireIFCD && !get(production, "deliveryCD.ifcd")) deliveryProblems.push(t("specifyIfcdCdNr"));
	}

	if (activeArticles.includes("mp3cd")) {
		if (!get(production, "master.mp3cd.settings.approved")) deliveryProblems.push(t("saveExportsForMp3"));

		const requireIFCD = distOptions && distOptions.mp3cd && distOptions.mp3cd.find((dist) => distConfig[dist].ifcd);
		if (requireIFCD && !get(production, "deliveryMP3CD.ifcd")) deliveryProblems.push(t("specifyIfcdNrForMp3"));
	}

	const prooferProgress = production?.proofer?.includes("NONE")
		? false
		: production.progress?.proofed?.time ||
		  production.progress?.proofed?.[
				production.proofer?.[0] ||
					(production.progress.proofed ? Object.keys(production.progress.proofed)[0] : null)
		  ];

	const handleUpload = (overriddenDistOptions) => {
		if (publisher && publisher.distributionOptions) {
			// setInitiatingDelivery(true);
			// automatic delivery
			deliverProduction({
				productionId,
				production,
				uid,
				profile,
				publisher,
				distributors,
				distOptions: overriddenDistOptions || distOptions,
				updateStatus,
				setInitiatingDelivery,
				handleChange,
				totalPartDuration,
			});
		} else {
			// legacy delivery, just change the status
			updateStatus("done");
			window.scrollTo(0, 0);
			window.alert(t("legacyUploadWarning"));
		}
	};

	const checkReminderComments = async () => {
		let comments = await db.collection("productions").doc(productionId).collection("comments").get();
		let reminderComments = [];
		for await (const doc of comments.docs) {
			const d = doc.data();
			if (d.completionReminder) reminderComments.push(d.text);
		}
		reminderComments.forEach((comment) => window.alert(t("reminderCommentWarning", { comment })));
		return true;
	};

	const finish = (distOptions) => {
		checkReminderComments().then(() => {
			if (
				(!production.stats ||
					production.productionType === "subcontract" ||
					(production?.stats?.pages && production?.stats?.pages?.total >= production?.pages)) &&
				window.confirm(t("confirmUpload"))
			) {
				handleUpload(distOptions);
			} else if (
				(!production?.stats?.pages || production?.stats?.pages?.total < production.pages) &&
				window.confirm(t("allPagesNotDone"))
			) {
				handleUpload(distOptions);
			}
		});
	};

	const isRecorder =
		profile?.permissions?.producer?.[production.producer]?.includes("producerRecorder") &&
		!profile?.permissions?.producer?.[production.producer]?.includes("producerAdmin");

	return (
		<>
			<Form as="div" style={{ overflow: "hidden" }}>
				<Header as="h4" icon="check" content={t("finishProd")} />
				<>
					{production.deliveryParts &&
					(!production.isbn ||
						(production.deliveryDuration &&
							totalPartDuration > durationToSec(production.deliveryDuration))) ? (
						<p>
							<b>
								{t("finalTotDuration")}{" "}
								{totalPartDuration ? secToDuration(totalPartDuration) : t("notSpecified")}
							</b>
						</p>
					) : (
						<Form.Input
							pattern="([0-9]{0,3})(:[0-5][0-9]){2}"
							value={deliveryDuration !== null ? deliveryDuration : production.deliveryDuration || ""}
							onChange={(e, data) => {
								setParentState({ deliveryDuration: data.value });
							}}
							label={t("finalTotalDuration")}
							placeholder={t("timerStart")}
							required
						/>
					)}

					{prooferProgress && (
						<p style={{ marginTop: "-1em" }}>
							<small>
								{t("prooferProgress")} {secToDuration(prooferProgress / 1000, false)}
							</small>
						</p>
					)}

					<Form.TextArea id="deliveryComment" label={t("infoAboutDel")} />

					{!!deliveryProblems.length && (
						<div>
							<b>{t("fixBeforeDelivery")}</b>
							<ul className="production-delivery-problems">
								{deliveryProblems.sort().map((problem, i) => (
									<li key={i}>{problem}</li>
								))}
							</ul>
						</div>
					)}
				</>

				{!isRecorder && (
					<Button
						content={initiatingDelivery ? t("deliverySchedules") : t("finalizeProd")}
						icon="right arrow"
						color="green"
						disabled={!!deliveryProblems.length || initiatingDelivery}
						loading={initiatingDelivery}
						labelPosition="right"
						onClick={(e) => {
							if (
								production.productionType === "subcontract" ||
								production.productionType === "external"
							) {
								setShowDistOptionsModal(true);
							} else {
								finish();
							}
						}}
					/>
				)}
			</Form>
			{showDistOptionsModal && (
				<FinishProductionDistOptionsModal
					prodRef={production.ref}
					distOptions={distOptions}
					finish={finish}
					setShowModal={setShowDistOptionsModal}
				/>
			)}
		</>
	);
};

export default withTranslation()(ProductionDelivery);
