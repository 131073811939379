import { Header } from "semantic-ui-react";

import ChannelReleases from "./ChannelReleases";
import ChannelsMenu from "./ChannelsMenu";

export default function App({ app, name, channel }) {
	return (
		<>
			<Header as="h2" textAlign="center">
				{name}
			</Header>

			<ChannelsMenu selectedChannel={channel} />

			<ChannelReleases app={app} channel={channel} />
		</>
	);
}
